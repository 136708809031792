<template>
  <div>
    <table-list
      class="tableList"
      :title="tableTitle"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :btns="btns(this)"
      :options="{ singleCurrent: true, fixScrollTable: true }"
      :columns="columns(this)"
      :pager="pager"
      @search="onSearch"
      @singleSelectChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
import { evaluationList, evaluationExport } from '@/api/examination'
import TableList from '@/components/TableList'
import to from 'await-to'
//表格标题
const tableTitle = '测评记录'
//表格按钮
const btns = _this => [
  {
    label: '所有测评详情',
    method: _this.handleDetail,
  },
  {
    label: '导出',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '姓名',
    prop: 'studentName',
  },
  {
    label: '手机号',
    prop: 'mobile',
  },
  {
    label: '测评名称',
    prop: 'evalName',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '测评人姓名',
  },
  {
    prop: 'evalName',
    label: '测评名称',
  },
  {
    prop: 'mobile',
    label: '手机号',
  },
  {
    prop: 'completeTotal',
    label: '测评总次数',
    align: 'right',
  },
  {
    prop: 'maxScore',
    label: '最高得分',
    align: 'right',
  },
  {
    prop: 'aveScore',
    label: '平均得分',
    align: 'right',
  },
  {
    prop: 'lastTime',
    label: '最近一次测评时间',
  },
  {
    prop: 'shortestTime',
    label: '最短答题用时（秒）',
    align: 'right',
  },
  {
    prop: 'aveTime',
    label: '平均答题用时（秒）',
    align: 'right',
  },
]
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      searchForm,
      columns,
      btns,
      loading: false,
      list: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  created() {
    this.evaluationListData()
  },
  methods: {
    async evaluationListData() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(evaluationList({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.records
      this.pager.total = res.data.total
    },
    handleSelectionChange(val) {
      //   console.log(val)
      this.$router.push({ path: '/examination/evaluationDetail', query: { name: val.studentName } })
    },
    handleDetail() {
      this.$router.push('/examination/evaluationDetail')
    },
    async handleExport() {
      const { current, size } = this.pager
      const [res, err] = await to(
        evaluationExport({ current, size, ...this.queryParams }, { responseType: 'blob' }),
      )
      if (err) return this.$message({ type: 'error', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '测评记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.evaluationListData()
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.evaluationListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.evaluationListData()
    },
  },
}
</script>

<style scoped></style>
